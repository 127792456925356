import { ToasterHook } from "../../../../contexts/ToasterContext";
import { useMutation } from "../../../../tools/api";
import { eventsTracker } from "../../../../universalFunctions/events";

export const useTransactionRefund = (setResult) => {
  const { successSnackBar } = ToasterHook();

  const afterSuccessRefund = (res) => {
    const { id, state } = res?.data?.data[0] || {};

    setResult((prev) => {
      const { array: prevArray = [] } = prev || {};

      const array = prevArray.map((item) => {
        const { id: mappedId } = item || {};
        if (id == mappedId) return { ...item, state };
        return item;
      });

      return { ...prev, array };
    });

    successSnackBar({
      msg: "Refund requested",
      showClose: true,
    });
  };

  const { mutation: mutationRefund } = useMutation({
    url: "/refund_transactions",
    method: "post",
    afterSuccess: afterSuccessRefund,
  });

  const handleRefund = async (values) => {
    const { originator_id: id } = values;
    const approve = [id];
    const result = { ...values, approve };

    await mutationRefund(result);
    eventsTracker("refund_transactions", result);
  };

  return { handleRefund };
};

import React from "react";
import { useRouter } from "next/router";
import topupIcon from "../../../assets/images/topup-icon.svg";
import { CustomButton } from "../../../components/Buttons";
import { LeftRightList } from "../../../components/Lists";
import { useModalHook } from "../../../components/Modals";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import { booleanState, StatusLabel } from "../../../components/Status";
import { FullDateFormatter } from "../../../components/tools";
import { CountDownTopUpModal } from "../../../pageComponents/homeComponents/topup/components";
import { expiresFormatter } from "../../../pageComponents/homeComponents/topup/logic";
import { TopUpPaymentInstructionModal } from "../../../pageComponents/homeComponents/topup/modals";
import { ActivityDetailsHeader } from "../activityModalComponents";
import { InvoicePaymentSupportingDocs } from "../invoicePayment/supportingDocs";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { HeaderModalTopup } from "./components";
import { CompareDate, useLogicTopup } from "./logic";

export const MaskingStateTopUp = (state) => {
  const { isCreated, isDraft, isOnProgress, isCancelled } = booleanState(state);
  if (isCreated || isDraft) return `on_progress`;
  if (isOnProgress) return "pending";
  if (isCancelled) return "failed";
  return state;
};

export const TopupDetailsModal = ({
  isOpen,
  toggle,
  data,
  // isSuccessModal = false,
}) => {
  const { push } = useRouter();
  const { isOpen: isOpenPaymentInstruction, toggle: togglePaymentInstruction } =
    useModalHook();
  const { id, status, isSuccessModal, state, originator_id, originator_type } =
    data || {};

  const isTopupVa = originator_type === "CollectionVirtualAccount";

  const {
    leftRightArray,
    headerData,
    showPay,
    isTopUpManual,
    attachData,
    dataFetch,
    loadingFetch,
  } = useLogicTopup({
    data,
    isSuccessModal,
  });

  const showProofOfPayment = isSuccessModal || isTopUpManual;

  const handlePay = () =>
    push({
      pathname: `/account/topup/${id}`,
      query: { wallet: "indonesian_wallet", type: "top_up" },
    });

  const { b2b_expiry_date } = dataFetch?.data || {};
  const { expires_in, created_at } = dataFetch?.details || {};

  // VA ONLY
  const { expiration_date, created_at: vaCreatedAt } = dataFetch || {};

  const dateDecider = isTopupVa ? expiration_date : b2b_expiry_date;
  const createdAtDecider = isTopupVa ? vaCreatedAt : created_at;

  const expiredIn = FullDateFormatter(dateDecider || "");
  const dataForCountDown = { expiredIn };
  const time = expiresFormatter(dateDecider);
  const isMoreThan10Minutes = CompareDate(createdAtDecider);

  const { isOnProgress, isWaitingPayment } = booleanState(state || status);
  const showPaymentCountDown = isOnProgress || isWaitingPayment;

  const components = [
    <div>
      <img src={topupIcon} style={{ marginBottom: 32 }} />
      <HeaderModalTopup data={headerData} />
      {showPaymentCountDown && (
        <CountDownTopUpModal
          time={time}
          data={dataForCountDown}
          showAlert={isMoreThan10Minutes}
          fromMainModal
          handlePaymentInstruction={togglePaymentInstruction}
          style={{ marginBottom: 32 }}
        />
      )}
      <LeftRightList array={leftRightArray} />
    </div>,
  ];

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={components}
        isLoading={loadingFetch}
        width={[420]}
      />
      ;
      {/* <RightCardModalAtomic
        isOpen={isOpen}
        loading={loadingFetch}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            close={isSuccessModal && <></>}
          />
        }
        headerStyle={{ paddingBottom: 48 }}
        middleHeight={middleHeight}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={topupIcon} />
          </MiddleWrapper>
        }
        paddingTop={18}
        body={
          <div style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StatusLabel status={MaskingStateTopUp(status || state)} />
              {showPay && (
                <CustomButton
                  className="w-100"
                  style={{ marginBottom: 24 }}
                  onClick={handlePay}
                >
                  Pay {totalAmount}
                </CustomButton>
              )}
            </div>
            {showPaymentCountDown && (
              <CountDownTopUpModal
                time={time}
                data={dataForCountDown}
                showAlert={isMoreThan10Minutes}
                fromMainModal
                handlePaymentInstruction={togglePaymentInstruction}
              />
            )}
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <LeftRightList array={leftRightArray} />
            </div>
            {showProofOfPayment && (
              <div>
                <InvoicePaymentSupportingDocs
                  array={attachData}
                  title="Proof of payments"
                />
              </div>
            )}
            {isSuccessModal && (
              <CustomButton className="w-100 mt-5" onClick={toggle}>
                Finish
              </CustomButton>
            )}
          </div>
        }
      /> */}
      <TopUpPaymentInstructionModal
        data={dataFetch}
        isOpen={isOpenPaymentInstruction}
        toggle={togglePaymentInstruction}
        isFromTrxPage
        // setAfterSuccessManual={() => console.log("success")}
      />
    </>
  );
};
export default TopupDetailsModal;

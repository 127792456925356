import { GothamMedium } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import logoIcon from "../../../assets/images/transfez-pink-small-logo.svg";
import { fetch } from "../../../tools/api";
import { useGetVaNumber } from "../../../pageComponents/homeComponents/topup/logic";
import { useEffect } from "react";

export const useLogicTopup = ({ data, isSuccessModal }) => {
  const {
    reference_number: headerLeft,
    created_at,
    currency,
    amount,
    originator_id,
    details,
    status,
    description,
    originator_type,
    reference_number,
    state: stateParent,
  } = data || {};

  const { va_number, bank_name, originator, va_id } = details || {};
  const { bank_code: bankCodeActivity } = originator || {};
  const isTopupVa = originator_type === "CollectionVirtualAccount";

  const {
    data: getData,
    refetch,
    loading,
  } = fetch({
    url: `/topups/${originator_id}`,
    formatter: (data) => data,
    defaultValue: {},
    woInit: true,
  });

  useEffect(() => {
    if (isTopupVa) return;
    refetch();
  }, []);

  const { vaData, loadingVaData } = useGetVaNumber({
    id: va_id,
    canRun: isTopupVa,
  });

  const { maker: vaMaker, bank: vaBank } = vaData || {};

  const {
    amount: amountFallback,
    createdAt: createdAtFallback,
    account_name: accNameManual,
    account_number: accNumberManual,
    amount: amountManual,
    bank_name: bankNameManual,
    file_details,
    reference_id: referenceIdManual,
    state,
    maker,
  } = getData?.data || {};
  const { bank_code } = getData?.details || {};

  const attachData = (getData?.attachment_data || []).map((item, index) => {
    const file_url = file_details[index].url;
    return { ...item, file_url };
  });

  const isHubVaTransaction = originator_type === "Hub::VaTransaction";
  const isHubPayment = originator_type === "Hub::Payment";
  const isFromHubTrx = isHubVaTransaction || isHubPayment;
  const isTopUpManual = originator_type === "Topup";
  const paymentMethod =
    isFromHubTrx || isTopupVa ? "Virtual Account" : "Bank Transfer";
  const bankName =
    bank_name ||
    bankNameManual ||
    (bank_code && bank_code.toUpperCase()) ||
    (bankCodeActivity && bankCodeActivity.toUpperCase()) ||
    vaBank;
  ("-");
  const referenceId = reference_number || referenceIdManual;

  const totalAmount = `IDR ${formatCurrency(amountManual || amount)}`;
  const date = customDateFormatter(created_at || createdAtFallback);
  const showPay = status == "initial";

  const maskingState = (state) => {
    if (state === "cancelled") return "failed";
    if (state === "expired") return "failed";
    if (state === "rejected") return "failed";
    if (state === "waiting_payment") return "pending";
    if (state === "in_progress") return "pending";
    return state;
  };

  const statusMaskingDecider = isTopupVa ? stateParent : state;

  const makerDecider = (isTopupVa ? vaMaker : maker) || "";

  const headerDataDefault = {
    value: `IDR ${formatCurrencyNoDecimal(amount || amountFallback)}`,
    status: maskingState(statusMaskingDecider),
    details: makerDecider ? `Created by ${makerDecider}` : "",
  };

  const headerDataSuccess = {
    icon: false,
    title: (
      <div style={{ textAlign: "center", marginTop: -30 }}>
        <img src={logoIcon} className="mb-3" />
        <GothamMedium style={{ fontSize: 24, marginBottom: 12 }}>
          Your top up request is in progress
        </GothamMedium>
      </div>
    ),
  };

  const headerData = isSuccessModal ? headerDataSuccess : headerDataDefault;

  const leftRightArray = [
    { left: "Created at", right: date },
    { left: "Reference", right: referenceId },
    { left: "Payment Method", right: paymentMethod },
    { left: "Bank Name", right: bankName },
  ];

  // const leftRightArray = isFromHubTrx ? leftRightArrayVA : leftRightArrayManual;

  return {
    leftRightArray,
    headerData,
    showPay,
    isTopUpManual,
    attachData,
    dataFetch: isTopupVa ? vaData : getData,
    loadingFetch: isTopupVa ? loadingVaData : loading,
  };
};

export const CompareDate = (createdDate) => {
  const newCurrentDate = new Date();

  var tenMinutesMore = new Date(createdDate);
  tenMinutesMore.setMinutes(tenMinutesMore.getMinutes() + 10); // timestamp
  tenMinutesMore = new Date(tenMinutesMore); // Date object

  const createdAtPlus10MinutesNumber = tenMinutesMore.getTime();
  const currentDateNumber = newCurrentDate.getTime();

  return createdAtPlus10MinutesNumber < currentDateNumber;
};

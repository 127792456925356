import React from "react";
import { jackColors } from "../../../assets/colors";
import { StatusLabelJack } from "../../../components/StatusLabel";
import { GothamMedium, GothamRegular } from "../../../components/Text";

export const HeaderModalTopup = ({ data }) => {
  const {
    title = "Top up company balance",
    value = "IDR 10,000",
    details,
    status = "completed",
  } = data || {};

  return (
    <div style={{ marginBottom: 32 }}>
      <GothamRegular className="mb-2">{title}</GothamRegular>
      <GothamMedium
        className="mb-2"
        style={{ fontSize: 24, lineHeight: "32px" }}
      >
        {value}
      </GothamMedium>
      <div className="d-flex justify-content-between align-items-center">
        {details ? (
          <GothamRegular
            className="mb-2 font12"
            style={{ color: jackColors.neutral700 }}
          >
            {details}
          </GothamRegular>
        ) : (
          <div></div>
        )}
        <StatusLabelJack status={status} isOutline />
      </div>
    </div>
  );
};

import { titleCase } from "change-case";
import { isEmpty, times } from "lodash";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ViewportList from "react-viewport-list";
import { jackColors } from "../../../assets/colors";
import external from "../../../assets/images/external-transaction-outlined.svg";
import empty from "../../../assets/jackImages/transactions-empty.svg";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { SkeletonTd } from "../../../components/Shimmer";
import { booleanState } from "../../../components/Status";
import { StatusAccountIconJack } from "../../../components/Status/JackStatusIcon";
import { StatusLabelJack } from "../../../components/StatusLabel";
import { TableJackSimple } from "../../../components/Table/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { TransitionTr } from "../../../components/Transition";
import {
  formatCurrencyNoDecimal,
  rearrangeDatesDescending,
  windowDimension,
} from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useLayout } from "../../../contexts/Layout/parent";
import { SmartActivityModal } from "../../../modals/smartActivityModal/parent";
import { MaskingStateTopUp } from "../../../modals/smartActivityModal/topup/parent";
import { useMutation } from "../../../tools/api";
import { EmptyList } from "./empty";
import { TransactionsFilters } from "./filters/parent";
import { transactionsBooleans } from "./logics/booleans";
import { useTransactionFiltersArray } from "./logics/filterArray";
import { useTransactionFilters } from "./logics/filters";
import { transactionsFormatter } from "./logics/formatter";
import { useTransactionRefund } from "./logics/refund";
import { eventsTracker } from "../../../universalFunctions/events";
import {
  CategoryLabel,
  MissingDetailWarning,
} from "../../categoryComponents/general/components";
import { categoryChecker } from "../../categoryComponents/general/helpers";
import { useCategoryFlag } from "../../categoryComponents/general/hooks";
import { cardTransactionStateDecider } from "../../cardPageComponents/tools";
import { useAutoOpen } from "../../notifications/useNotifications";
import {
  TRANSACTIONS_FOURTH_TARGET,
  TRANSACTIONS_THIRD_TARGET,
} from "../../productDemoComponents/constants";

const usersFormatter = (users) => {
  const array = (users || []).map(({ name, email, id }) => ({
    label: name || email,
    value: id,
  }));
  return array;
};

export const TransactionsPage = ({
  type,
  isStopQuery,
  isRecent,
  style,
  // setIsTourReady = () => {},
}) => {
  const { isMine } = transactionsBooleans(type);

  const activeFilters = ["product", "date", "team", "status"];
  const filters = useTransactionFiltersArray(type);

  const tableTotalWidth = 1136;
  const tableWidth = (number) => `${(number / tableTotalWidth) * 100}%`;

  const [page, setPage] = useState(1);
  const { payload, runQuery } = useTransactionFilters(page, type);
  const userIds = !isEmpty(payload?.filters?.user_id);

  const [isMaxedOut, setIsMaxedOut] = useState(false);

  const { pathname } = useRouter();

  const { categories } = useConstants();

  const {
    isRequiredCategory,
    isNotRequiredCategory,
    shallCheckMissingCategory,
  } = useCategoryFlag();

  const { mutation, loading, result, setResult } = useMutation({
    url: "/activity_search",
    method: "post",
    resultFormatter: (res, prev) =>
      transactionsFormatter(res, prev, setIsMaxedOut),
  });
  const {
    array: arrayRaw = [],
    hasBalanceHistory,
    hasMissingCategory,
  } = result;

  const array = rearrangeDatesDescending(
    isRecent ? arrayRaw.slice(0, 5) : arrayRaw
  );

  const showAlertColumn =
    hasBalanceHistory || (hasMissingCategory && isRequiredCategory);

  const showCategoryColumn =
    (isRequiredCategory || isNotRequiredCategory) &&
    pathname !== "/category/details/[id]";

  const headerArr = [
    showAlertColumn && {
      label: "",
      width: tableWidth(40),
    },
    {
      label: "Created at",
      width: tableWidth(94),
      textAlign: "left",
    },
    {
      label: "Transaction",
      width: tableWidth(!isMine ? 362 : 510),
      textAlign: "left",
    },
    {
      label: "Status",
      width: tableWidth(140),
      textAlign: "left",
    },
    !isMine && {
      label: "Created By",
      width: tableWidth(148),
      textAlign: "left",
    },
    {
      label: "Source Account",
      width: tableWidth(148),
      textAlign: "left",
    },
    showCategoryColumn && {
      label: "Category",
      width: tableWidth(148),
      textAlign: "left",
    },
    {
      label: "Amount",
      width: tableWidth(148),
      textAlign: "right",
    },
  ];

  const refetch = () => mutation(payload);
  const { id } = useGetAuth().user || {};
  // paginate
  const { canRefetch } = useLayout();

  const listener = (payload) => {
    const isChangingCategory = payload?.isChangingCategory;

    if (isChangingCategory) {
      const updatedArray = result?.array?.map((item) => {
        const isCurrentItem = item?.id === payload?.id;

        if (isCurrentItem) return payload;
        return item;
      });

      return setResult((prev) => ({ ...prev, array: updatedArray }));
    }

    const hasAttachment = payload?.has_attachment ? true : 0;
    const hasNotes = !!payload?.notes;
    const editedCardTrx = result.array?.find(
      ({ originator_id }) => originator_id === payload.id
    );
    const updatedCardTrx = {
      ...editedCardTrx,
      details: payload,
      has_attachment: hasAttachment,
      has_notes: hasNotes,
    };

    const updatedArray = result.array.map((item) =>
      item.originator_id === updatedCardTrx.originator_id
        ? (item = updatedCardTrx)
        : item
    );
    setResult((prev) => {
      return { ...prev, array: updatedArray };
    });
  };

  useEffect(() => {
    // refetch for when userIds is done forming for the first time for team managers
    if (type !== "team") return;
    setPage(1);
    refetch();
  }, [userIds]);

  useEffect(() => {
    if (!id) return;
    refetch();
  }, [page, id]);

  useEffect(() => {
    if (isRecent) return;
    if (!canRefetch) return;
    if (isMaxedOut) return;
    if (loading) return;
    setPage((p) => p + 1);
  }, [canRefetch]);
  // paginate

  useEffect(() => {
    if (isStopQuery) return;
    if (runQuery !== "true") return;
    setResult({});
    setIsMaxedOut(false);
    if (page == 1) return refetch();
    setPage(1);
  }, [runQuery]);

  const [selectedItem, setSelectedItem] = useState(false);

  // handles auto open from email

  const { mutation: autoOpen } = useMutation({
    url: "/activity_search",
    afterSuccess: (res, prev) => {
      const { array } = transactionsFormatter(res, prev, () => {}) || {};
      const isArray = Array.isArray(array);

      if (!isArray) return;
      if (!array.length) return;
      const [obj] = array;
      setSelectedItem(obj);
    },
    method: "post",
  });

  // handles auto open from email
  useEffect(() => {
    const id = Number(localStorage.getItem("autoOpenTrx"));
    const originator_id = Number(localStorage.getItem("autoOpenTrxOrigin"));

    if (id) {
      autoOpen({ filters: { id } });
      localStorage.setItem("autoOpenTrx", "");
    }

    if (originator_id) {
      autoOpen({ filters: { originator_id } });
      localStorage.setItem("autoOpenTrxOrigin", "");
    }
  }, []);

  const { push, query } = useRouter();

  // handles auto open from notif

  // useEffect(() => {
  //   const {id , type, isOpen } = query ?? {}
  //   if (id && isOpen) {
  //     setSelectedItem({originator_id: id, type})
  //     delete query?.isOpen
  //     push({query})
  //   }
  // }, [query?.id, query?.type, query?.isOpen])

  useAutoOpen(setSelectedItem);

  const handleClose = () => setSelectedItem(false);

  const { handleRefund } = useTransactionRefund(setResult);

  const tbodyRef = useRef();

  const { allUsers: usersRaw } = useConstants();

  const users = usersFormatter(usersRaw);

  const isEmptyArray = !array.length;

  const { height } = windowDimension();

  const emptyList = () => {
    const componentHeight = height - (80 + 32 + 41 + 128 + 20);
    const { menu, runQuery, ...rest } = query;
    const isFiltered = !!Object.values(rest).filter((item) => item).length;

    const title = isFiltered ? "Transaction not found" : "Nothing to see here";
    const msg = isFiltered
      ? "You can try another keyword"
      : "Start creating your first transaction by clicking the “Create” button";

    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: componentHeight, flexDirection: "column" }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            flexDirection: "column",
          }}
        >
          <img src={empty} />
          <GothamMedium
            className="font20"
            style={{
              textAlign: "center",
              marginBottom: 8,
              marginTop: 16,
            }}
          >
            {title}
          </GothamMedium>
          <GothamRegular
            style={{ color: jackColors.grey6C, textAlign: "center" }}
          >
            {msg}
          </GothamRegular>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isRecent) return;
    if (isEmptyArray) return;
    push({ query: { isTourReady: true } });
  }, [isEmptyArray, isRecent]);

  return (
    <div
      style={{
        marginTop: isRecent ? 0 : 32,
        marginLeft: isRecent ? -8 : 32,
        marginRight: isRecent ? -8 : 32,
        ...style,
      }}
    >
      {!isRecent && (
        <>
          <TransactionsFilters
            activeFilters={activeFilters}
            filters={filters}
            loading={loading}
          />
          <div style={{ height: 24 }} />
        </>
      )}
      {isEmptyArray && !loading ? (
        emptyList()
      ) : (
        <TableJackSimple
          isAllowOverflow
          isDumb={isRecent}
          headerTable={headerArr}
          tbodyRef={tbodyRef}
          bodyComponent={
            <>
              <ViewportList
                viewportRef={tbodyRef}
                items={array}
                itemMinSize={100}
                margin={8}
                overscan={10}
              >
                {(item, index) => {
                  const {
                    date,
                    category,
                    description,
                    state,
                    status,
                    source_account,
                    amount,
                    is_debit,
                    has_attachment,
                    has_notes,
                    is_external,
                    user_id,
                    details,
                    payment_method,
                    originator_type,
                  } = item;
                  const {
                    isCrossBorder,
                    isTopUp,
                    isWalletHistory,
                    isCardGeneral,
                    isPayroll,
                    isLocalDisbursementBatch,
                    isMainBalance,
                    isMoveBalance,
                    isCardMoveBalance,
                  } = booleanState(category, payment_method);
                  const { isPartialFailed } = booleanState(state);
                  const isJournalEntry =
                    originator_type === "Hub::JournalEntry";

                  const { category_id } = details ?? {};

                  const {
                    hasCategory,
                    isBatchCategory,
                    categoriesCount,
                    hasMultipleCategories,
                  } = categoryChecker(item);

                  const isMissingCategory =
                    !hasCategory && shallCheckMissingCategory;

                  const trxCategory =
                    hasCategory &&
                    (categories ?? []).find(({ id }) => {
                      const categoryIds = isBatchCategory
                        ? category_id
                        : [category_id];
                      const hasCategory = categoryIds.length > 0;

                      if (hasCategory) {
                        return id === categoryIds[0];
                      }

                      return false;
                    });

                  const sourceAccountDecider = () => {
                    if (source_account == "e_wallet") return "Team Wallet";
                    if (source_account == "undecided") return "-";
                    return titleCase(source_account);
                  };

                  const amountDecider = () => {
                    const defaultAmount = `IDR ${formatCurrencyNoDecimal(
                      amount
                    )}`;

                    if (amount && is_debit) return <>&#8212; {defaultAmount}</>;

                    if (amount) return defaultAmount;

                    return "-";
                  };

                  const imageDecider = () => {
                    const getImages = () => {
                      const hasImage =
                        (isWalletHistory ||
                          isCardGeneral ||
                          isCardMoveBalance ||
                          isMissingCategory) &&
                        !isJournalEntry;
                      if (!hasImage) return [];

                      if (isWalletHistory) {
                        if (is_external) return <img src={external} />;
                        if (has_attachment) return ["receipt_add"];
                        return ["receipt_not"];
                      }

                      const hasNotes = has_notes || Boolean(details?.notes);

                      const isMainBalance = payment_method === "main_balance";
                      const isMoveBalance = payment_method === "move_balance";
                      const isBackOffice = payment_method === "manual by Jack";
                      const isWoReceipt = isMainBalance || isMoveBalance;

                      const isMissingReceipt = !isWoReceipt && !has_attachment;
                      const { isSuccess } = cardTransactionStateDecider(state);

                      const isMissingReceiptNotes =
                        !isBackOffice &&
                        isSuccess &&
                        (isMissingReceipt || !hasNotes);
                      const isMissingDetails =
                        isMissingReceiptNotes || isMissingCategory;

                      if (isCardGeneral || isCardMoveBalance) {
                        if (isMissingDetails) {
                          return <MissingDetailWarning />;
                        }
                        // const receipt = has_attachment
                        //   ? "receipt_add"
                        //   : "receipt_not";
                        // const note = has_notes ? "memo_add" : "memo_not";
                        // if (isMainBalance || isMoveBalance) return [note];
                        // return [receipt, note];
                      }
                      if (isMissingCategory) return <MissingDetailWarning />;
                    };

                    const images = getImages();
                    const isArray = Array.isArray(images);

                    if (isArray) {
                      if (!images.length) return null;

                      return (
                        <div className="d-flex" style={{ gap: 8 }}>
                          {images.map((name, index) => (
                            <JackIcons
                              key={index}
                              name={name}
                              style={{ width: 20, height: 20 }}
                              fill={jackColors.greyBB}
                            />
                          ))}
                        </div>
                      );
                    }

                    return images;
                  };

                  const getStatus = () => {
                    if (
                      (isLocalDisbursementBatch || isPayroll) &&
                      isPartialFailed
                    )
                      return state;
                    if (isTopUp) return MaskingStateTopUp(status);
                    return status;
                  };

                  const userDecider = () => {
                    const obj = users.filter(
                      ({ value }) => value == user_id
                    )[0];
                    return obj?.label || "-";
                  };

                  // DEMO PURPOSE
                  const demoId =
                    type !== "team" && index === 0
                      ? TRANSACTIONS_THIRD_TARGET
                      : `row-${id}`;

                  return (
                    <TransitionTr
                      isQuick
                      counter={index}
                      perPage={20}
                      key={index}
                      onClick={() => {
                        eventsTracker(
                          isRecent
                            ? "transactions_open_transaction_modal"
                            : "open_activity_modal",
                          item
                        );
                        setSelectedItem(item);
                      }}
                    >
                      {showAlertColumn && (
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            {imageDecider()}
                          </div>
                        </td>
                      )}
                      <td>{date}</td>
                      <td>
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: 12 }}
                        >
                          <StatusAccountIconJack
                            state={category}
                            source={source_account}
                          />
                          <GothamRegular
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "calc(100% - 36px)",
                            }}
                          >
                            {description}
                          </GothamRegular>
                        </div>
                      </td>
                      <td id={demoId}>
                        <StatusLabelJack status={getStatus()} isOutline />
                      </td>
                      {!isMine && (
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {userDecider()}
                        </td>
                      )}
                      <td>{sourceAccountDecider()}</td>
                      {showCategoryColumn && (
                        <td>
                          {trxCategory ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              {hasMultipleCategories ? (
                                <CategoryLabel
                                  icon="category"
                                  color={jackColors.neutral900}
                                  upperText="Multiple"
                                  lowerText={`${categoriesCount} Categories`}
                                />
                              ) : (
                                <CategoryLabel
                                  icon={trxCategory?.icon}
                                  color={trxCategory?.color}
                                  upperText={trxCategory?.name}
                                  lowerText={trxCategory?.account_code}
                                />
                              )}
                            </div>
                          ) : (
                            <GothamRegular
                              woFontColor
                              style={{
                                color: jackColors.neutral900,
                                maxWidth: "100%",
                                textWrap: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              -
                            </GothamRegular>
                          )}
                        </td>
                      )}
                      <td>
                        <GothamRegular
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: "right",
                          }}
                        >
                          {amountDecider()}
                        </GothamRegular>
                      </td>
                    </TransitionTr>
                  );
                }}
              </ViewportList>

              {loading &&
                times(isRecent ? 5 : 10, (index) => (
                  <tr key={index}>
                    {times(showAlertColumn ? 6 : 5, (index) => (
                      <SkeletonTd key={index} />
                    ))}
                  </tr>
                ))}
            </>
          }
        />
      )}
      {isRecent && !isEmptyArray && (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ height: 40 }}
        >
          <GothamMedium
            style={{
              color: jackColors.black34,
              textAlign: "center",
            }}
            onClick={() => {
              eventsTracker("home_to_activity");
              push("/transactions");
            }}
            className="hover"
          >
            See all transactions
          </GothamMedium>
        </div>
      )}
      <SmartActivityModal
        isOpen={Boolean(selectedItem)}
        toggle={handleClose}
        data={selectedItem}
        handleRefund={handleRefund}
        setData={setResult}
        listener={listener}
      />
    </div>
  );
};
